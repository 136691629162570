var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "", persistent: "" },
      scopedSlots: _vm._u(
        [
          _vm.isCreateReadmeFile
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g({ attrs: { color: "secondary", text: "" } }, on),
                      [
                        _c("v-icon", { attrs: { left: "" } }, [_vm._v("add")]),
                        _vm._v(" add readme file "),
                      ],
                      1
                    ),
                  ]
                },
              }
            : _vm.editable
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "justify-start",
                          attrs: {
                            color: _vm.btnColor,
                            text: !_vm.loading,
                            block: _vm.isBlock,
                            disabled: _vm.disabled,
                            loading: _vm.loading,
                          },
                        },
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { left: "", small: "" } }, [
                          _vm._v("edit"),
                        ]),
                        _vm._v(" edit "),
                      ],
                      1
                    ),
                  ]
                },
              }
            : {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "justify-start",
                          attrs: {
                            color: _vm.btnColor,
                            text: !_vm.loading,
                            block: _vm.isBlock,
                            disabled: _vm.disabled,
                            loading: _vm.loading,
                          },
                        },
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { left: "", small: "" } }, [
                          _vm._v("mdi-eye"),
                        ]),
                        _vm._v(" view "),
                      ],
                      1
                    ),
                  ]
                },
              },
        ],
        null,
        true
      ),
      model: {
        value: _vm.fileEditorDialog,
        callback: function ($$v) {
          _vm.fileEditorDialog = $$v
        },
        expression: "fileEditorDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-space-between align-center title secondary--text w-100",
              },
              [
                _vm.isCreateReadmeFile
                  ? _c(
                      "div",
                      { staticClass: "d-flex align-center text-uppercase" },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [_vm._v("add")]),
                        _vm.isCreateReadmeFile
                          ? _c("span", [_vm._v("Add Readme file")])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [_vm._v("edit")]),
                        _c("div", { staticClass: "d-flex title flex-column" }, [
                          _c("span", { staticClass: "font-weight-medium" }, [
                            _vm._v("Edit File"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "secondary--text subtitle-2" },
                            [
                              _vm._v(
                                _vm._s(
                                  [
                                    _vm.fileData.local_path,
                                    _vm.fileData.short_id,
                                  ].join("/")
                                )
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _vm.editable
                      ? [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", disabled: _vm.fileLoading },
                              on: { click: _vm.discardChanges },
                            },
                            [_vm._v("discard")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                loading: _vm.fileLoading,
                                color: "primary",
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateFileContent()
                                },
                              },
                            },
                            [_vm._v("save")]
                          ),
                        ]
                      : _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              disabled: _vm.fileLoading,
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.fileEditorDialog = false
                              },
                            },
                          },
                          [_vm._v("close")]
                        ),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "px-0 pb-0" },
            [
              _vm.isMarkdownFile && _vm.fileEditorDialog
                ? _c("MarkdownEditor", {
                    attrs: {
                      editorHeight: "calc(100vh - 83px)",
                      editorText: _vm.fileContent,
                      editorMode: _vm.originalFileContent.includes("<iframe")
                        ? "markdown"
                        : "wysiwyg",
                      fetchingFile: _vm.fetchingFile,
                    },
                    on: {
                      newMarkdownContent: function ($event) {
                        _vm.fileContent = $event.value
                      },
                    },
                  })
                : _c("MonacoEditor", {
                    staticClass: "monaco-editor",
                    style: { height: "calc(100vh - 82px)", overflow: "hidden" },
                    attrs: {
                      language: _vm.language,
                      options: {
                        minimap: { enabled: false },
                        fontSize: 14,
                        renderLineHighlight: false,
                        automaticLayout: true,
                        autoIndent: true,
                        lineNumbersMinChars: 5,
                        scrollbar: { vertical: "visible" },
                        scrollBeyondLastLine: false,
                        smoothScrolling: true,
                        readOnly: !_vm.editable,
                      },
                    },
                    model: {
                      value: _vm.fileContent,
                      callback: function ($$v) {
                        _vm.fileContent = $$v
                      },
                      expression: "fileContent",
                    },
                  }),
              _c(
                "div",
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "500" },
                      model: {
                        value: _vm.changeWarningDialog,
                        callback: function ($$v) {
                          _vm.changeWarningDialog = $$v
                        },
                        expression: "changeWarningDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _c(
                              "span",
                              { staticClass: "title secondary--text" },
                              [_vm._v("Discard changes")]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "mb-1" }),
                          _c("v-card-text", [
                            _c("p", [_vm._v("You have unsaved changes.")]),
                            _c("p", [
                              _vm._v(
                                "Are you sure you want to discard your changes?"
                              ),
                            ]),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "error", text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.fileEditorDialog = false
                                    },
                                  },
                                },
                                [_vm._v("discard")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "secondary", text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.changeWarningDialog = false
                                    },
                                  },
                                },
                                [_vm._v("review changes")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }